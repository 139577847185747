body.order-show {
  position: fixed;
  overflow: hidden;
  width: 100%;
  // height: 100vh;
  .site-wrap {
    transform: translateY(-50px);

      opacity: 0;

  }

  .order {
    transform: translateY(0%);
  }
}
.order {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  // transform: translateY(0%);
  transform: translateY(100%);
  transition: transform 1s ease-out;
  background: linear-gradient(to bottom, #5BBCCE, #FAFAFA);
  &__inner{
    @include vw($margin-top: 220);
    @include respond-to(xs){
      margin-top: 91px;
    }
  }
  .container {
    @include vw($max-width: 1230, $padding-right: 15, $padding-left: 15);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @include respond-to(xs) {
      padding-right: 25px;
      padding-left: 25px;
    }
  }
&__bg{
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
  @include respond-to(xs){
    padding-bottom: 220px;
  }
}
  .header {
    @include vw($margin-bottom: 20);
    &__inner {
      padding-bottom: 0;
      z-index: 999;
      align-items: center;
      @include vw($padding-top: 75,
      $max-width: 1300);
      @include respond-to(xs) {
        padding-top: 40px;
        padding-right: 35px;
        padding-left: 35px;
      }
    }
    &__logo {
      @include vw($width: 180);

      @include respond-to(xs) {
        width: 95px;
      }

      img {
        width: 100%;
      }
    }
    &__btn {
      @include vw($font-size: 42, $border: 0, $padding: 10 10,  $border-radius: 15);
      font-family: $ff-bold;
      @include respond-to(xs){
        font-size: 12px;
        background:  $brand;
        color: #fff;
        padding: 5px;
      }
     ;
    }
  }
&__img{
  @include vw($width: 170,
  $margin: 54 auto 0);
  @include respond-to(xs){
    width: 62.39px;
    margin: 10px 30px;
  }
}
  &__title {
    text-align: center;
    color: #fff;
    @include vw($margin-bottom: 0,
    $font-size: 42);
    font-family: $ff-bold;
    @include respond-to(xs) {
      font-size: 20px;
      padding: 0;
      margin-bottom: 18px;
      color: #000;
    }
    &--detail {
    }
  }

  &__subtitle {
    color: #fff;
    text-align: center;
    @include vw($font-size: 35);

    @include respond-to(xs) {
      font-size: 18px;
      width: 90%;
      margin: 0 auto;
      font-family: $ff-bold;
      color: #000;
    }

  }
  &__plan {
    display: flex;
    justify-content: center;
    // align-items: flex-start;
    max-width: 100%;
    width: 100%;
    @include vw($padding: 0 140 50);
    transition: all 1.2s ease;

    &.scale-out {
      transform: scale(0.5) translate(0%, -50%);
      transform-origin: center;
    }

    @include respond-to(xs) {
      margin-top: 10px;
      width: 100%;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 0px;
    padding: 0px 0 0;
    }
  }

  &__options {
    display: flex;
    justify-content: center;
    @include vw($margin-top: 115);
    transition: all 1.2s ease;
    opacity: 0;
    z-index: 0;
    visibility: hidden;
    @include respond-to(xs) {
      padding: 25px 0 20px;

      width: 100%;
      margin-top: 16px;
    }
    &[data-options='list'] {
    }
    &[data-options='slider'] {
      position: absolute;
      top: 0;
      left: 50%;
      transition: opacity 1.2s ease;
      transform: scale(1.4) translateX(-35%);
      width: 100%;
      @include vw($margin-top: 30);
      @include respond-to(xs) {
        margin-top: 5px;
        padding: 0px 0 20px;
      }
      .slick-track {
        @include vw($padding-top: 45);
        @include respond-to(xs){
          padding-top: 60px;
        }
      }
      .option-card {
        @include vw($width: 225, $margin-right: 20);
        @include respond-to(xs) {
          margin-right: 5px;
          width: 160px;
        }
      }
    }
    &[data-options='checkout'] {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 0%);
      @include vw($margin-top: 0);
      &.scale-in {
        transform: scale(1.4) translate(-34%, 10%);
        transform-origin: center;
        @include respond-to(xs) {
          transform: scale(1.4) translate(-26%, 10%);
        }
        .order-card__back-link {
          display: none;
        }
        .checkout__btn {
          @include respond-to(xs) {
            align-items: center;
            justify-content: space-between;
            width: 250px;
            margin: 20px 0 0 0;
            overflow: hidden;
          }
          .order-card__back-link {
            transform: none;
            display: flex;
            position: absolute;
            // bottom: 50px;
            top: auto;
            @include vw($bottom: 50);
            @include respond-to(xs){
              position: static;
            }
          }
        }
        .checkout__info-row {
          @include respond-to(xs) {
            max-width: 250px;
          }
        }
        .checkout__info-row--sm {
          @include respond-to(xs) {
            width: 250px;
          }
        }
        .checkout__disclaimer {
          @include respond-to(xs) {
            width: 280px;
            font-size: 14px;
          }
        }
      }
    }
    &.is-shown {
      opacity: 1;
      z-index: 3;
      visibility: visible;
    }
  }
  &__detail {
    transition: all 1.2s ease;
    position: relative;
  // display: none;
  opacity: 0;
height: 0;
overflow: hidden;
&.is-show{
  opacity: 1;
  height: auto;
  overflow: visible;
}
    &-inner {
      position: relative;
    }
    &.scale-in {
      transform: translateY(-15vw);
      transform-origin: center;
      @include respond-to(xs) {
        transform: translateY(-140px);
      }
    }
  }
}

.order-card {
  background: #fff;
  @include vw($border: 5 solid #10121f, $border-radius: 8, $padding: 31 10 5);
  transition: $trans;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;

  @include respond-to(xs) {
    border: 0;
    padding: 12px 3px 0;
    border-radius: 12px;;
    // height: 151px;
  }
  &:hover {
    @include vw($border: 5 solid $brand);
    @include respond-to(xs) {
      border: 4px solid $brand;
    }
  }
  &__header {
    position: absolute;
    width: 90%;
    transform: translate(-50%, -100%);
    background:  #FCEEA3;
    color: #919191;
    top: 0;
    left: 50%;
    @include vw($font-size: 30, $top: -5);
    font-family: $ff-bold;
    text-align: center;
    @include vw(
      $padding: 10,
      $border-top-left-radius: 8,
      $border-top-right-radius: 8
    );
    z-index: 0;
    @include respond-to(xs) {
      font-size: 16px;
      padding: 5px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      top: 0;
    }
    &--small {
      @include vw($font-size: 29,
      $padding: 5);
      font-family: $ff-bold;

      @include respond-to(xs) {
        padding: 2px;
        font-size: 10px;
      }
    }
    &--green {
      background: #008AC1;
      color: #fff;
    }
  }

  &__subtitle {
    text-align: center;
    font-family: $ff-bold;
    color: #919191;
    position: relative;
    @include vw($font-size: 15, $margin-bottom: 20);
    transition: all 1.2s ease;
    @include respond-to(xs) {
      font-size: 8px;
      margin-bottom: 31px;
      margin-bottom: 10px;
      color: #919191
    }
    &--bottom{
      @include respond-to(xs){
font-size: 10px;
color: #000;

      }
    }
  }

  &__title {
    position: relative;
    @include vw($font-size: 42, $margin-bottom: 5);
    color: #008AC1;
    text-align: center;
    transition: all 1.2s ease;
    font-family: $ff-bold;
    @include respond-to(xs) {
      // font-size: 26px;
      // margin-bottom: 0;
      margin-bottom: 0px;
      font-size: 18px;
    }
    &--bottom {
      @include vw($margin-bottom: 23);
      @include respond-to(xs){
        margin-bottom: 5px;
      }
    }
  }

  &__price {
    position: relative;
    text-align: center;
    @include vw($margin-bottom: 30);
    @include respond-to(xs) {
      margin-bottom: 4px;
      font-size: 20px;
    }
    transition: all 1.2s ease;
    &-sum {
      transition: all 1.2s ease;
      font-family: $ff-bold;
      display: block;
      color: #000;
      @include vw($font-size: 42);
      line-height: 1.3;
      @include respond-to(xs) {
        font-size: 20px;
        margin-bottom: 5px;
      }
    }

    &-info {
      transition: all 1.2s ease;
      font-family: $ff-bold;
      display: block;
      color: #919191;
      @include vw($font-size: 15);
      @include respond-to(xs) {
        font-size: 10px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    transition: all 1.2s ease;
    @include vw($margin-top: 48);
    @include respond-to(xs){
      margin-top: 13px;
      margin-bottom: 9px;
    }
    &-title{
      @include vw($font-size: 28,
      $margin-bottom: 5);
      font-family: $ff-bold;
      color: $brand;
      @include respond-to(xs){
        font-size: 16px;
        padding-left: 5px;
      }
    }
    &-item {
      flex-basis: 100%;
      @include vw($font-size: 18, $margin-bottom: 5);
      font-family: $ff-bold;
      color: #000;
      // margin-right: 2%;
      @include respond-to(xs){
        margin-right: 0;
        font-family: $ff-bold;
        font-size: 6px;
        margin-bottom: 5px;

          padding-left: 0;
          font-size: 10px;
          padding-left: 5px;

      }
    }
  }
  &__card-icon {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    @include vw($width: 50);
    @include respond-to(xs) {
      width: 20px;
    }
  }

  &__back-link {
    position: absolute;
    left: 0%;
    // top: 50%;
    @include vw($bottom: 50);
    // transform: translateX(-100%);
    @include vw($width: 54, $height: 54, $margin-left: -300);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.4s ease;
    box-shadow: 0 0 40px -5px rgba(0,0,0,0.5);
    @include respond-to(xs) {
      width: 28px;
      height: 28px;
      margin-left: 10px;
      left: -13px;
      top: 80px;
    }
    img {
      transform: rotate(90deg);
      width: 43%;
    }
  }
}

.plan-card {
  flex-basis: 48%;
  @include vw(
    $border: 5 solid transparent,
    $border-radius: 15,
    $max-width: 534,
    $padding: 0
  );
  background: #fff;
  transition: $trans;
  position: relative;

  @include respond-to(xs) {
    max-width: 200px;
    border: 0;
    border-radius: 15px;
    flex-basis: 100%;
    padding: 0;
    background-size: 100%;
    margin-bottom: 25px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    background: url(/images/order-moon.png) top left no-repeat;
    background-size: 100%;
    @include vw($top: -40,
    $left: -90,
    $width: 170,
    $height: 170);
    background-size:  !important;;
    @include respond-to(xs){
      top: -38px;
      left: auto;
      right: -52px;
      width: 90px;
      height: 90px;
    }
  }

  &--subscribe {
    margin-left: 5%;
    &:hover{
      cursor: default;
    }
    .order-card__title{
      opacity: 0.3;
    }
    .order-card__subtitle{
      opacity: 0.3;
    }
    .order-card__list{
      opacity: 0.3;
    }
    &:hover {
      border-color: transparent !important;
      .plan-card__btn {
        color: #fff;
      }
    }
    @include respond-to(xs){
      margin-left: 0;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      background: url(/images/order-sun.png) top left no-repeat;
      background-size: 100% !important;

    @include vw($top: -60,
    $left: auto,
    $right: -90,
    $width: 170,
    $height: 170);
    @include respond-to(xs){
      top: -38px;
      right: auto;
      left: -62px;
      width: 90px;
      height: 90px;
    }
    }
  }

  &__inner {
    @include vw($padding: 20 20);
    @include respond-to(xs) {
      padding: 11px 6px 5px;
    }
  }

  &:hover {
    border-color: $brand;
    .plan-card__btn {
      color: #fff;
    }
  }

  &.is-active {
    border-color: $brand;
  }

  &__box {
    position: absolute;
    width: 124px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(87%, 20%);
    @include respond-to(xs) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: $ff-bold;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 100%);
    @include vw(
      $bottom: -30,
      $font-size: 21,
      $border: 4 solid $brand,
      $border-radius: 8,
      $width: 260,
      $height: 67
    );
    cursor: default;
    @include respond-to(xs) {
      display: none;
    }
  }
}

.option-card {
  flex-basis: 32%;
  flex-grow: 0;
  flex-shrink: 0;
  @include vw($max-width: 286, $padding: 20 10 50);
  border-color: transparent;
  margin: 0 2%;
  background: #fff;
@include respond-to(xs){
  max-width: 160px;
  padding: 5px 5px 15px;
}
  .order-card__title {
    @include respond-to(xs) {
      margin-bottom: 0px;
      font-size: 16px;
    }
  }
  .order-card__subtitle {
    color: #444444;
    @include vw($margin-bottom: 60);
    @include respond-to(xs) {
      font-size: 10px;
      margin-bottom: 13px;
    }
  }
}

.checkout {
  flex-wrap: wrap;
  @include respond-to(xs) {
    display: block;
  }
  &-card {
    @include vw($border: 5 solid $brand);

    margin-right: 1.495%;
    @include vw($max-width: 260);
    @include respond-to(xs) {
      border: 4px solid $brand;
      max-width: 100px;
      float: left;
      margin-right: 10px;
    }
  }
  &-info {
    flex-basis: auto;
    position: relative;
    @include vw($max-width: 340);
    @include respond-to(xs){
      max-width: 100%
    }
  }
  &__title {
    @include vw($font-size: 42, $margin-bottom: 5);
    color: #fff;
    position: relative;
    transition: all 1.2s ease;
    font-family: $ff-bold;
    @include respond-to(xs) {
      font-size: 26px;
      margin-bottom: 10px;
      padding: 10px 0 5px;
      line-height: 0.6;
      display: inline-block;
    }
    &:after {
      content: '';
      height: 2px;
      width: 70%;
      background: #e7e7e7;
      display: inline-block;
      /* bottom: 0; */
      /* left: 0; */
      margin-top: -10px;
      margin-bottom: 18px;
      position: relative;
      @include respond-to(xs) {
        margin: 0;
      }
    }
  }
  &__info {
    &-row {
      @include vw($margin-bottom: 5);
      font-family: $ff-bold;
    }
    &-title {
      font-family: $ff-bold;
      color: #fff;
      text-transform: uppercase;
      @include vw($font-size: 16);
      @include respond-to(xs) {
        font-size: 12px;
      }
    }
    &-params {
      color: $brand;
      @include vw($font-size: 18);
      @include respond-to(xs) {
        font-size: 12px;
      }
    }
    &-off {
      display: block;
      @include vw($margin-top: 5,
      $font-size: 14);
      font-style: italic;

    }
    &-additional {

      display: inline-block;
      font-family: $ff-bold;
      @include vw($margin-top: 10, $margin-bottom: 30, $font-size: 14);
      @include respond-to(xs) {
        font-size: 12px;
        margin-bottom: 10px;
      }
      p{
        font-family: $ff-bold;
        @include vw($font-size: 17);
        margin-bottom: 0;
      }
    }
  }
  &__disclaimer {
    flex-basis: 100%;
    font-family: $ff;
    @include vw($margin-top: 30, $margin-bottom: 30, $font-size: 16);
    display: none;
    p {
      @include vw($max-width: 550);
      margin: 0 auto;
    }
  }
  &__btn {
    @include respond-to(xs) {
      display: flex;
      justify-content: center;
    }
    .order-card__back-link {
      display: none;
    }
  }
}
.quality {
  background: #0c0c11;
  position: relative;
  margin-top: -1px;
  padding-top: 106px;
  .title {
    font-size: 51px;
    color: #fff;
    margin-bottom: 50px;
    width: 100%;
    position: relative;
    opacity: 0.2;
  }
  &__img {
    position: absolute;
    bottom: -130px;
    right: -200px;
    width: 420px;
    opacity: 0.2;
    img {
      width: 100%;
    }
  }
  &__subtitle {
    font-family: 'Gill-Sans-Nova-Bold', sans-serif;
    font-size: 21px;
    margin-bottom: 20px;
    opacity: 0.2;
  }
  &__content {
    font-family: 'Gill-Sans-Nova-Light', sans-serif;
    font-size: 18px;
    opacity: 0.2;
    a {
      color: #fff;
      font-family: 'Gill-Sans-Nova-Bold', sans-serif;
      line-height: 1.6;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}
