
@font-face {
    font-family: 'Proxima Nova Bold';
    src:
      url('/fonts/Proxima-Nova/Proxima-Nova-Bold.otf') format("opentype");
    font-style: normal;
  }
@font-face {
    font-family: 'Proxima Nova Medium';
    src:
      url('/fonts/Proxima-Nova/Proxima-Nova-Semibold.otf') format("opentype");
    font-style: normal;
  }
@font-face {
    font-family: 'Proxima Nova';
    src:
      url('/fonts/Proxima-Nova/Proxima-Nova-Regular.otf') format("opentype");
    font-style: normal;
  }