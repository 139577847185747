@import "system/variables";
/******  HELPERS  ******/
.color-brand {
    color: $brand;
  }

  .color-white {
    color: #fff;
  }

  .color-yellow {
    color: #FFB200;
  }
  .color-black {
    color: #000;
  }
  .color-dark {
    color: #010001;
  }

  .text-center {
    text-align: center;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

