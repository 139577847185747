@import 'system/variables';
@import 'system/mixin';
body, html{
  overflow-x: hidden;
  width: 100%;
}
img {
  width: 100%;
}
p {
  //   font-size: 20px;
  @include vw($font-size: 20);
  color: #878787;
  font-family: $ff;
  line-height: 1.25;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
strong {
  font-family: $ff-bold;
}
.mobile-visible {
  display: none;
  @include respond-to(xs) {
    display: block;
  }
}
.desktop-visible {
  display: block;
  @include respond-to(xs) {
    display: none;
  }
}
.site-wrap {
  transform: translateY(0%);

  opacity: 1;
  transition: all 0.6s ease;
}
.container {
  @include vw($max-width: 1170, $padding-right: 15, $padding-left: 15);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @include respond-to(xs) {
    padding-right: 30px;
    padding-left: 30px;
  }
}
.btn {
  @include vw(
    $font-size: 18,
    $border: 2 solid $brand,
    $padding: 5 10,
    $border-radius: 50
  );
  font-family: $ff;
  line-height: 1.15;
  background: $brand;
  color: #fff;
  @include respond-to(xs) {
    font-size: 15px;
  }
  &--round {
    background: transparent;
    //   font-size: 22px;
    @include vw(
      $font-size: 17,
      $padding: 6 10,
      $margin: 0 0 0,
      $border: 2 solid #000
    );
    color: #000;
    @include respond-to(xs) {
      font-size: 9px;
      padding: 6px 10px;
      margin: 0px 0 0;
      border: 0;
    }
  }
  &__text {
    font-family: $ff;
    // padding-right:  8px;
    @include vw($padding-right: 8);
    @include respond-to(xs) {
      padding-right: 0;
    }
  }
  &__arrow {
    font-family: $ff;
    // padding-right:  8px;
    @include vw($padding-right: 8);
    transform: translateX(0);
    display: inline-block;
    transition: $trans;
  }
  &:hover {
    .btn__arrow {
      transform: translateX(5px);
    }
  }
}
.title {
  text-align: center;
  font-size: 38px;
  @include vw($font-size: 38);
  @include respond-to(xs) {
    font-size: 17px;
  }
  &--lg {
    @include vw($font-size: 42, $margin-bottom: 47);
    font-family: $ff-bold;
    @include respond-to(xs) {
      font-size: 19px;
      margin-bottom: 20px;
    }
  }
  &--md {
    @include vw($font-size: 30, $margin-bottom: 55);
    font-family: $ff-bold;
    @include respond-to(xs) {
      margin-bottom: 17px;
      font-size: 20px;
    }
  }
}
.card {
  border: 1px solid #707070;
  padding: 50px 103px 103px;
  @include vw($border: 1 solid #707070, $padding: 50 103 103);
  border-radius: 0;
  @include respond-to(xs) {
    border: 1px solid #000;
    padding: 20px 45px 30px;
  }
  &-descr {
    @include vw($margin-bottom: 94);
    @include respond-to(xs) {
      margin-bottom: 16px;
      line-height: 1.2;
      font-size: 15px;
    }
  }
}
.header {
  position: absolute;
  width: 100%;
  z-index: 2;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-top: 45px;
    // padding-bottom: 36px;
    @include vw($padding-top: 45, $padding-bottom: 36, $max-width: 1230);
    @include respond-to(xs){
      padding-top: 40px;
    padding-bottom: 35px;
    padding-right: 45px;
    padding-left: 45px;
    }
  }
  &__logo {
    // width: 175px;
    @include vw($width: 175);
    @include respond-to(xs) {
      width: 76px;
    }
  }
  &__btn{
    @include respond-to(xs){
     background:  transparent;
     border: 1px solid #000;
     padding: 7px 12px;
     border-radius: 12px;
     color: #000;
     font-family: $ff-bold;
    }
  }
}
.hero {
  position: relative;
  // overflow: hidden;
  @include vw($margin-bottom: 0);
  background: url(/images/hero.jpg)  no-repeat;
  // height: 100vh;
  @include vw($padding-bottom: 130,
  $background-position: 0px -30);

  background-size: 100%;
  @include respond-to(xs) {
    background:  url(/images/bg-mobile.jpg)  no-repeat;
    height: 80vh;
    margin-bottom: 40px;
    background-position: top left;
    padding-bottom: 0px;
    margin-bottom: 30px;
    background-size: cover;
  }
  .container {
    @include vw($max-width: 1320);
    @include respond-to(xs){
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  &__title {
    text-align: left;
    width: 47%;
    margin: 0 auto;
    color: #fff;
    font-family: $ff-bold;
    @include vw($padding-top: 170,
    $font-size: 42);
    @include respond-to(xs){
      width: 100%;
    font-size: 2.5vh;
    padding-top: 15vh;
    }
  }
  &__content {
    width: 100%;
    @include vw($max-width:505, $margin-top: 180, $margin-left: 86);
    @include respond-to(xs){
      max-width: 100%;
      margin: auto auto 0;
    }
    &-title {
      // padding-top: 124px;
      text-align: left;
      // width: 62%;
      // margin: 0 auto;
      color: #000;
      font-family: $ff-bold;
    @include vw($font-size: 42);
      @include respond-to(xs){
        font-size: 3.3vh;
        margin-bottom: 1vh;
      }

    }
    &-text {
      color: #878787;
      @include vw($font-size: 16,
      $max-width: 272);
      @include respond-to(xs){
        max-width: 100%;
        font-size: 2.4vh;
        margin-bottom: 6vh;
      }
    }
    &-btn {
      @include vw(
      $max-width: 305);
      @include respond-to(xs){

      }

    }
    .btn {
      @include vw($font-size: 21);
      @include respond-to(xs){
        font-size: 18px;
        padding: 10px 36px;
        margin-bottom: 4vh;
      }
    }
  }
  &__img {
    position: absolute;
    max-width: 100%;
    &.plane {
      @include vw($width: 179, $top: 380, $right: 300);
      @include respond-to(xs){
        width: 66px;
    top: 30vh;
    right: 97px;
      }
    }
    &.sun {
      @include vw($width: 310, $top: 110, $right: -120);
      @include respond-to(xs){
        width: 110px;
        top: 21vh;
        right: -40px;
      }
    }
    &.icon1 {
      @include vw($width: 50, $top: 652, $left: 640);
      @include respond-to(xs){
        width: 25px;
        top: 43vh;
        left: 108px;
      }
    }
    &.icon2 {
      @include vw($width: 35, $top: 730, $left: 453);
      @include respond-to(xs){
        width: 16.345px;
        top: 336.892px;
        left: 303.123px;
        display: none;
      }
    }
    &.icon3 {
      @include vw($width: 30, $top: 820, $left: 279);
      @include respond-to(xs){
        width: 16px;
        top: 70vh;
        left: 60px;
      }
    }
    &.icon4 {
      @include vw($width: 35, $top: 926, $left: 119);
      @include respond-to(xs){
        width: 15.345px;
        top: 307.642px;
        left: 49.373px;
        display: none;
      }
    }
    &.icon5 {
      @include vw($width: 30, $top: 1056, $left: 369);
      @include respond-to(xs){
        width: 17px;
        top: 77vh;
        left: 296px;

      }
    }
    &.icon6 {
      @include vw($width: 30, $top: 1146, $left: 169);
      @include respond-to(xs){
        display: none;
      }
    }
  }
}

.preorder {
  position: relative;
  z-index: 1;
  @include vw($margin-top: 8);
  @include respond-to(xs){
    margin-top: 80px;
  }
  &__title {
    @include vw($font-size: 42, $margin-bottom: 36);
    @include respond-to(xs) {
      font-size: 22px;
      width: 75%;
      margin: 0 auto 105px;
      text-align: left;
    }
  }
  &__wrap {
    display: flex;
    width: 100%;
    margin-left: auto;
    @include vw($max-width: 795);
    @include respond-to(xs) {
      flex-wrap: wrap;
    }
  }
  &__img {
    position: relative;
    flex-basis: 43%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: -5%;
    margin-left: 5%;
    @include vw($margin-top: -35);
    @include respond-to(xs) {
      order: 1;
      flex-basis: 100%;
      margin-top: -58px;
      position: relative;
      z-index: 5;
      display: none;
    }
    &-bg {
      border-radius: 52%;
      position: absolute;
      background: $bg;
      z-index: -1;
      @include vw($width: 336, $height: 336, $top: 0);
      left: 50%;
      transform: translate(-50%, 10%);
      @include respond-to(xs) {
        top: 60%;
        left: 50%;
        width: 160px;
        height: 130px;
        transform: translate(-50%, -50%);
      }
    }
    &-img {
      width: 110%;
      text-align: center;
      @include vw($padding-top: 0, $margin-left: -19, $margin-top: -30);
      @include respond-to(xs) {
        width: 180px;
        margin: auto;
      }
    }
  }
  &__card {
    flex-basis: 57%;
    @include vw($margin: 40 0 0);
    @include respond-to(xs) {
      order: 0;
      flex-basis: 100%;
      margin: 17px 0;
    }
    p {
      @include vw($font-size: 16);
      @include respond-to(xs){
        display: none;
      }
    }
    .btn {
      display: none;
      @include respond-to(xs) {
        border: 1px solid #000;
        padding: 4px 10px;
        font-size: 5px;
      }
    }
  }
  .card-descr{
max-width: 80%;
@include respond-to(xs){
  max-width: 100%;
}
  }
}
.safe {
  position: relative;
  z-index: 0;
  @include vw($margin-top: -160, $padding-top: 73, $padding-bottom: 137);
  @include respond-to(xs) {
    margin-top: -185px;
    padding-top: 162px;
    padding-bottom: 80px;
  }
  .arrow{
    @include respond-to(xs){
      width: 10px;
      top: -50px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__title {
    margin-top: 52px;
    margin-bottom: 70px;
    @include vw($margin-top: 45, $margin-bottom: 86);
    @include respond-to(xs) {
      margin-top: 5px;
      margin-bottom: 20px;
      display: none;
    }
  }
  &__icon {
    text-align: center;
    @include vw($height: 65, $margin: auto auto 20, $width: 65);
    border-radius: 50%;
    background: rgba(38, 201, 207, 0.34);
    // background:  rgba(38, 201, 207, 0.34);
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-to(xs) {
      margin-bottom: 21px;
      height: 50px;
      width: 50px;
    }
    img {
      height: 80%;
      display: block;
      width: auto;
    }
  }
  &__inner {
    justify-content: space-between;
    display: flex;
    @include respond-to(xs) {
      flex-wrap: wrap;
    }
  }

  &__text {
    line-height: 1.5;
    @include vw($font-size: 18);
    @include respond-to(xs) {
      // width: 80%;
      margin: auto;
      line-height: 1.2;
      font-size: 16px;
      text-align: center;
      text-align: left;
    padding: 0 15px;
    }
  }
  &__col {
    flex-basis: 33%;
    @include vw($padding-left: 15);
    text-align: center;
    @include respond-to(xs) {
      flex-basis: 100%;
      margin-bottom: 40px;
      padding-left: 0;
    }
    &-title {
      font-family: $ff-bold;
      margin-bottom: 0;
      @include vw($font-size: 20,
      $margin-bottom: 10);
      @include respond-to(xs) {
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    &:first-child {
      .safe__icon {
        img {
          height: 90%;
        }
        @include respond-to(xs) {
          height: 50px;
        }
      }
    }
    &:nth-child(2) {
      .safe__icon {
        background: #acc8ff;
      }
    }
    &:last-child {
      .safe__icon {
        background: #fceea3;
        img {
          height: 70%;
        }
        @include respond-to(xs) {
          height: 50px;
        }
      }
    }
  }
  &__line{
    @include vw($bottom: -173, $height: 232);
    @include respond-to(xs){
      height: 70px;
    bottom: -10px;
    }
  }
}

.arrow {
  margin: 0 auto;
  @include vw($width: 24);
  @include respond-to(xs) {
    width: 10px;
  }
  img {
    display: block;
  }
}
.line {
  background: #000;
  margin: auto auto 0;
  position: absolute;
  left: 50%;
  @include vw($width: 3);
  @include respond-to(xs) {
    width: 2px;

  }
}

.info {
  overflow: hidden;
  @include vw($padding-top: 75);
  background: url(/images/bg-blue.png) top center no-repeat;
  background-size: contain;
  @include vw($padding-top: 145, $margin-top: 10,);
  @include respond-to(xs) {
    margin-top: -45px;
    padding: 35px 0 70px;
  }
  &__bg {
    background: #e0eaff;
    @include vw($padding-top: 119,  $padding-bottom: 320,
    $margin-top: -10);
    @include respond-to(xs){
      padding-bottom: 213.44px;
      margin-top: 0;
      padding-top: 82px;
    }
  }
  &__title {
    @include vw($margin-bottom: 245,
    $font-size: 60);
font-family: $ff-bold;
line-height: 1;
    @include respond-to(xs) {
      font-size: 27px;
      margin-bottom: 17px;
      line-height: 1.3;
      margin-bottom: 110px;
    }
  }
  &__row {
    display: flex;
    margin-bottom: 160px;
    align-items: center;
    @include vw($margin-bottom: 210);
    align-items: flex-start;
    justify-content: space-between;
    @include respond-to(xs) {
      flex-wrap: wrap;
      margin-bottom: 70px;
      flex-wrap: wrap;
    }
    &--left {
      .info-card {
        flex-basis: 40%;
        flex-grow: 0;
        order: 1;
        display: flex;
        @include vw($padding-left: 30,
        $padding-top: 19);
        @include respond-to(xs) {
          order: 1;
          flex-basis: 88%;
          min-height: 0;
          margin: auto;
          padding-left: 0;
        }
      }
      .info__img {
        order: 2;
        flex-basis: 41%;
        margin-right: 0%;

        @include respond-to(xs) {
          flex-basis: 55%;
          margin: 0 auto;
          padding: 0;
          order: 0;
        }
      }
    }
  }
  &-card {
    flex-grow: 0;
    order: 0;
    margin: 0;
    flex-basis: 42%;
    display: flex;
    @include respond-to(xs) {
      order: 0;
      flex-basis: 88%;
      min-height: 0;
      margin: auto;
    }
    .title {
      @include vw($font-size: 35, $margin-top: 15, $margin-bottom: 30);
      text-align: left;
      @include respond-to(xs){
        text-align: center;
        font-size: 20px;
        margin-top: 15px;
        margin-bottom: 15px;

      }
    }
    .card {
      min-height: 100%;
      @include vw($padding: 40 43 40);
      @include respond-to(xs) {
        padding: 15px 20px 25px;
      }
    }
    p {
      //   font-size: 23px;
      @include vw($font-size: 16);
      color: $grey;
      line-height: 1.6;
      @include respond-to(xs) {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 10px;

      }
    }
  }
  &__img {
    flex-grow: 0;
    margin-right: 0%;
    padding: 0 0 0 0;
    order: 0;
    flex-basis: 41%;
    @include respond-to(xs){
      flex-basis: 55%;
      margin: 0 auto;
      padding: 0;
    }
  }
.btn{
  @include respond-to(xs){
    display: none;
  }
}
}

.bg-grey {
  background: $grey-light;
}

.what-is {
  @include vw($padding-top: 140,
  $margin-top: -210,
  );
  background:  url('/images/bg-2.png') top center no-repeat;
  background-size: contain;
  overflow: visible;
  @include respond-to(xs) {
    margin-top: 0px;
    overflow: visible;
    padding-bottom: 30px;
    padding-top: 59px;
    margin-top: -230px;
  }
  .arrow{
    margin-top: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    @include vw($top: -180);
    @include respond-to(xs){
      top: -83px;
    }
  }
  .container{

    @include vw($padding-top: 100);
    @include respond-to(xs){
      padding-top: 45px;
    }
  }
  &__bg{
    background:  #FCEEA3;
    height: 100%;
    @include vw($padding-top: 160,
    $padding-bottom: 200);
    position: relative;
    @include respond-to(xs){
      padding-top: 55px;
      padding-bottom: 133.4px;
      margin-top: -20px;
      padding-bottom: 73.4px;
    }
  }
  .info__title {
    @include vw($margin-top: 0,
    $margin-bottom: 130,
    $font-size: 60);
    font-family: $ff-bold;
    @include respond-to(xs) {
      font-size: 27px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .info__row {
    @include vw($margin-bottom: 206);
    align-items: center;
    padding: 0 6%;
    // overflow: hidden;
    @include respond-to(xs) {
      margin-bottom: 92px;

      padding: 0 30px;
    }
    &--left{
      .info-card{
        @include vw($padding: 0);
      }
      .info__img{
        padding: 0;
        flex-basis: 61%;
        margin-right: -34%;
        @include respond-to(xs){
          flex-basis: 100%;
    margin-right: 0%;
    margin-top: -49px;
    margin-bottom: 133px;
        }
        img{
          @include respond-to(xs){
            width: 130%;
          }
        }
      }
    }
    &:first-child {
      @include respond-to(xs) {
      }
      .info__img {
@include vw($margin-bottom: -140);
        @include respond-to(xs) {
          flex-basis: 90%;
          margin: -25px auto 0;
        }
      }
    }
    &:nth-child(2) {
      @include respond-to(xs) {
        position: relative;
        margin-bottom: 150px;
      }
      .info__img {
        @include respond-to(xs) {
          margin-top: -84px;
          margin-bottom: 143px;
          z-index: 9;
          width: 38%;
          margin-right: 0%;
          position: absolute;
          bottom: -110px;
          left: -28px;
        }
        img {
          @include respond-to(xs) {
            transform: rotate(-118deg);
          }
        }
      }
    }
    &:last-child {
      @include respond-to(xs) {
        position: relative;
      }
      .info__img {
        @include respond-to(xs) {
          overflow: hidden;
          margin-top: 17px;
          position: absolute;
          width: 140px;
          z-index: 9;
          right: -10px;
          transform: rotate(8deg);
          bottom: -50px;
        }
        img {
          width: 170%;
          transform: translate(-16%, 0%);

        }
      }
    }
    &:last-child {
      // margin-bottom: 70px;
      @include vw($margin-bottom: 197);
      @include respond-to(xs) {
        margin-bottom: 20px;

      }
    }
  }
  .info-card {
    flex-basis: 46%;
    @include respond-to(xs) {
      flex-basis: 100%;
    }
    .card {
      border: none;
      @include vw($border-radius: 28,
      $padding: 40);

      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

      @include respond-to(xs) {
        border-radius: 18px;
        padding: 10px 22px 30px;
      }
      .title {
        @include vw($font-size: 20,
        $padding: 0,
        $margin-bottom: 46);
        text-align: center;
        @include respond-to(xs) {
          width: 75%;
          text-align: left;
          line-height: 1;
          margin: 8px auto 12px;
          font-size: 20px;
          padding: 0;
        }
      }
        p {
          @include vw($padding: 0 10);
          @include respond-to(xs) {
            line-height: 1.15;
            font-size: 16px;
          }
        }
      .btn {
        @include respond-to(xs) {
          background: #fff;
          font-size: 16px;
          border-radius: 18px;
          border: 2px solid #000;
          color: #000;
          padding: 3px 5px;
          display: inline-block;
          &__arrow {
            display: none;
          }

        }
      }
      p {
        @include respond-to(xs) {
          margin-bottom: 19px;
          font-size: 16px;
        }
      }
    }
  }
  .info__img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 47%;
    padding: 0;
    @include vw($padding: 0);
    @include respond-to(xs){
      order: 3
    }
  }
  .line{
@include vw($bottom: 20);
  }
  &__footer {
    @include vw($font-size: 34,$margin-top: 90);
    color: #000;
    font-family: $ff-bold;
    text-transform: uppercase;
    text-align: center;
    @include respond-to(xs){
      margin-top: 120px;
    }
    span{
      @include vw($width: 40,
      $margin-left: 30,
      $margin-bottom: 40);
      display: inline-block;
      @include respond-to(xs){
        width: 40px;
        margin-bottom: 20px;
      }
    }
    @include respond-to(xs) {
      font-size: 20px;
      font-family: $ff-bold;
      width: 80%;
      margin: 0 auto;
      width: 52%;
      margin: 0 auto;
    }
  }
}
.review {
  @include vw($padding-top: 100,  $margin-top: -95);
  overflow: visible;
  position: relative;
  background:  url(/images/bg-3.png) top center no-repeat;
background-size: contain;
  @include respond-to(xs) {
    margin-top: -45.365px;
    padding-top: 15px;
    padding-bottom: 0px;
  }
  .line{
    @include vw($top: -40, $bottom: auto, $height: 160);
    @include respond-to(xs){
      top: -21.68px;
      bottom: auto;
      height: 80px;
    }
  }
  &__bg{
    background:  #D1F3FF;
    @include vw($padding-top:100, $padding-bottom: 152,);
    @include respond-to(xs){
      padding-top: 79px;
      padding-bottom: 73px;
    }
  }
  .title {
    @include vw($margin-bottom: 10,
    $font-size: 60);
    @include respond-to(xs) {
      margin-bottom: 0;
      font-size: 20px;
    }
  }
}
.slider {
  background: url(/images/quote.png) top left no-repeat;
  // padding: 50px 105px;
  position: relative;
  @include vw($padding: 50 80 70 130, $background-size: 200);
  @include respond-to(xs) {
    padding: 10px 18px 0px;
    background-size: 42px;
  }
  &__text {
    // font-size: 42px;
    @include vw($font-size: 42);
    color: #000;
    font-family: $ff-bold;
    @include respond-to(xs) {
      font-size: 16px;
      color: #4a4a4a;
      font-family: $ff-bold;
    }
  }
  .slick-prev {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-120%,100%);
    border: 0;
    border-radius: 50%;
    background: url(/images/arrow-left.png) center center no-repeat;
    background-size: cover !important;
    @include vw($width: 70, $height: 70);
    @include respond-to(xs) {
      width: 21px;
      height: 21px;
      transform: translate(-124%, 50%);
    }
  }
  .slick-next {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(20%, 100%);
   border: 0;
    @include vw($width: 70, $height: 70);
    border-radius: 50%;
    background: url(/images/arrow-right.png) center center no-repeat;
    background-size: cover !important;
    @include respond-to(xs) {
      width: 21px;
      height: 21px;
      transform: translate(24%, 50%);
    }
  }
}
.footer {
  &__inner {
    display: flex;
    @include vw($padding-top: 117, $padding-bottom: 22, $max-width: 1040);
    @include respond-to(xs) {
      padding-top: 24px;
    }
  }
  &__title {
    text-align: left;
    // margin-bottom: 23px;
    @include vw($margin-bottom: 23);
    @include respond-to(xs) {
      margin-bottom: 3px;
      text-align: center;
      text-align: center;
      margin-bottom: 25px;

    }
  }
  p {
    @include respond-to(xs) {
      font-size: 16px;
      margin-bottom: 5px;
    }
    br {
      display: none;
      @include respond-to(xs) {
        display: inline;
      }
    }
  }
  &__col {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    @include vw($padding-left: 30);
    @include respond-to(xs){
      padding-left: 0;
      flex-basis: 100%;
    }
    &:first-child {
     @include respond-to(xs){
       display: none;
     }
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.modal-dialog {
  @include vw($margin: 130 auto, $max-width: 1140);
  border: 0;
  border-radius: 0;
  @include respond-to(xs){
    margin: 30px auto;
    max-width: 96%;
  }
}
.modal-header {
  background: $brand;
  border-radius: 0;
  @include vw($height: 180);
  float: none;
    text-shadow: none;
    opacity: 1;
  @include respond-to(xs){
    height: 60px;
  }
}
.modal-content {
  border-radius: 0;
}
.modal-body {
  @include vw($padding: 0);
}
.suppl {
  @include vw($padding: 18 62 50, $font-size: 34);
  font-family: $ff;
  @include respond-to(xs){
    padding: 20px 10px 8px;
    font-size: 12px;
  }
  &__table {
    @include vw($padding-right: 70);
    @include respond-to(xs){
      padding-right: 10px;
    }
  }
  &__title {
    @include vw($font-size: 95, $margin-bottom: 20);
    font-family: $ff-bold;
    // text-align: center;
    @include respond-to(xs){
      font-size: 36px;
      margin-bottom: 15px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    @include vw($padding-bottom: 5, $border-bottom: 7 solid #000);
    @include respond-to(xs){
      border-bottom: 3px solid #000;
      padding-bottom: 5px;

    }
  }
  &__subheader {
    display: flex;
    justify-content: flex-end;
    @include vw($border-bottom: 7 solid #000, $padding-bottom: 5);
    @include respond-to(xs){
      border-bottom: 3px solid #000;
      padding-bottom: 5px;
       padding-top: 5px;
    }
    div {
      &:first-child {
        @include vw($padding-right: 115);
        @include respond-to(xs){
          padding-right: 35px;
        }
      }
    }
  }
  &__row {
    @include vw($padding: 13 0 13, $border-bottom: 1 solid #000);
@include respond-to(xs){
  border-bottom: 1px solid #000;
  padding: 10px 0;
}
    p {
      @include vw($font-size: 34);
      font-family: $ff;
      width: 80%;
      color: #000;
      @include respond-to(xs){
font-size: 16px;
      }
      &:last-child {
        @include vw($margin-bottom: 0);
      }
    }
  }
  &__footer {
    @include vw($padding: 13 0 13, $border-top: 6 solid #000);
    @include respond-to(xs){
      border-top: 2px solid #000;
      padding: 10px 0;
    }
    p {
      @include vw($font-size: 34, $margin-bottom: 10);
      font-family: $ff;
      color: #000;
      @include respond-to(xs){
        font-size: 16px;
        margin-bottom: 10px;
      }
      &:last-child {
        @include vw($margin-bottom: 0);
      }
    }
  }
}
